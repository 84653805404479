body {
    --theme-primary-color: #{$primary};
    --theme-primary-darker-color: #{darken($primary, 8%)};
    --theme-primary-lighter-color: #{lighten($primary, 8%)};

    // Default dark
    background-color: $theme-dark-background;
    --theme-secondary-color: #{darken($theme-dark-background, 8%)};
    --theme-inverted-background-color: #{darken($theme-dark-background, 8%)};
    --theme-text-color: #{$white};


    transition: background-color .2s ease-out;

    &.dark {
        background-color: $theme-dark-background;
        --theme-secondary-color: #{darken($theme-dark-background, 8%)};
        --theme-inverted-background-color: #{darken($theme-dark-background, 8%)};
        --theme-text-color: #{$white};
        --theme-row-color: #474747;
    }

    &.light {
        background-color: #{$white};
        --theme-secondary-color: #{$primary};
        --theme-inverted-background-color: #{$white};
        --theme-text-color: #{$black-ter};
        --theme-row-color: #dbdbdb;
    }
}

// Appointments loader
.appointments__loading {
    .sk-folding-cube .sk-cube:before {
        background-color: #333333;
    }
}

.dark {
    .appointments__loading {
        .sk-folding-cube .sk-cube:before {
            background-color: #ffffff;
        }
    }

    .search-container .search-box {
        border-color: var(--theme-inverted-background-color);
    }
}

.dark .app-header {
    background-color: transparent !important;
    color: white !important;
}

.dark .workorder-nav {
    background: $grey-dark;

    color: $white-ter;

    .title, strong {
        color: $white-ter;
    }
}

.light .workorder-nav {
  background: $grey-lighter;

  color: $black;

  .title, strong {
    color: $black;
  }
}

// Navigation
.dark .navigation__item {
    background: $grey-dark;


    .heading {
        color: $white-ter !important;
    }

    &.selected {
        background: var(--theme-primary-color) !important;
        .heading {
            color: $primary-invert !important;
        }
    }
}

// Navigation
.light .navigation__item {
  background: $grey-lighter;

  .heading {
    color: $black !important;
  }

  &.selected {
    background: var(--theme-primary-color) !important;
    .heading {
      color: $primary-invert !important;
    }
  }
}
