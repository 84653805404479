.panel {
}
.panel__header {
    background-color: var(--theme-primary-color);
    color: $white-ter;
    padding: .65rem .75rem;
    font-weight: $weight-bold;
    font-size: $size-3;
    transition: background-color .2s ease-out;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &.has-loading-icon {
        .loader {
            display: inline-block;
            position: relative;
            top: 2px;
        }
    }
}

.panel__sub-header {
    position: relative;
    background-color: var(--theme-primary-lighter-color);
    color: darken($white-ter, 5%);
    font-weight: bold;

    transition: background-color .2s ease-out;

    display: flex;
    justify-content: space-around;

    & > .panel__sub-header-item {
        cursor: pointer;
        padding: .75rem;
        flex: 1;
        text-align: center;
        font-size: $size-6;

        &.is-active {
            .has-arrow {
                color: $white;
            }
            .has-arrow.arrow-right::after {
                transform: rotate(90deg);
            }
        }
    }
}

.panel__body {
    background-color: $white-ter;
    border: 1px solid $grey-lighter;
    padding: .75rem;

  &.dark {
    background-color: $theme-dark-background;
    --theme-secondary-color: #{darken($theme-dark-background, 8%)};
    --theme-inverted-background-color: #{darken($theme-dark-background, 8%)};
    --theme-text-color: #{$white};
    --theme-row-color: #474747;
  }
}

.close {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
}

.panel__row {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1.75rem 1.25rem .75rem;
    border: 1px solid $grey;
    border-top: none;

    &.is-one-third {
        width: calc(100% / 3);
    }
    &.is-one-half {
        width: calc(100% / 2);
    }

    &.align-right {
        align-self: flex-end;
    }
}

.panel__rows {
    display: flex;
    flex-flow: column nowrap;
}
