.courtesy-inspection {
  .collapsable__content {
    padding-left: 1.55rem !important;
    padding-right: 1.55rem !important;
  }
}

.maintenance-services {
  & > .panel > .section-body {
    padding: 0 !important;
  }
}

.twoColumnGrid > fieldset > .panel > .section-body {
  display: grid;
  grid-template-columns: repeat(2, 3fr);
};

.fiveColumnGrid > fieldset > .panel > .section-body {
  display: grid;
  grid-template-columns: 4fr repeat(4, 3fr);
}

.fiveColumnGrid > fieldset > .panel > .section-body > .questionSwitcher:first-child {
  grid-row: 1 / span 2;
  display: grid;

  .is-centered-vertical {
    align-items: center !important;
  }
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: repeat(2, 3fr);
}

.maintenance-wrapper > fieldset > .panel > .section-body > app-question-switcher {
  width: 50%;

  @media screen and (min-width: $size-tablet) {
    width: initial;
  }
}

.custom-title {
  .title {
    font-size: xx-large !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
  }
  margin-left: auto !important;
  margin-right: auto !important;
  background-color: lightgrey;
}

.maintenance-wrapper > fieldset > .panel > .section-body {
  flex-flow: row wrap;

  @media screen and (min-width: $size-tablet) {
    flex-flow: row nowrap;
  }
}

  // Wrapper class for
.maintenance-wrapper {
    display: block;
    padding: .25rem 1rem .25rem;
    margin: 0;
    border-bottom: 1px solid $grey-lighter;
    position: relative;

    fieldset {
      .section-body {
        display: flex;
      }
      .panel > .section-body {
        justify-content: stretch;
        align-items: stretch;
        padding: .5rem 0;

        app-question-switcher:last-child {
          flex: 1;
          margin-left: 10px;
        }

      }

      .wrapper-panel {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        @media (min-width: $size-small) {
          width: 50%;
          margin-bottom: 0;
        }

        & > div {
          flex: 1;
        }
        .section-body {
          justify-content: flex-start;
        }
      }
    }

  .label {
    font-size: .825rem;
  }

  .panel > .section-body {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
  }

  .section-header:not(.itemNeeded) app-item-add {
    position: absolute;
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    transform: translate(-50%, 0);
  }

  .section-header.itemNeeded app-item-add {
    display: block;

    .field, button {
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .question-container, .form-group {
    height: 100%;
    width: 100%;
  }

  .question-wrapper {
    display: flex;
    flex: 1 2 0;

    &:first-child {
      flex: 1.2 0 0;
      app-question-switcher + app-question-switcher {
        flex: 1;
      }
    }

    &:nth-child(2) {
      display: flex;
      app-question-switcher {
        display: inline-flex;
        width: 100%;
        & + app-question-switcher {
          margin-left: 10px;
        }
      }
    }

  }

  .form-checkbox {
    .field > .label {
      margin-top: 0;
    }
    .form-checkbox__options {
      display: flex;
      label {
        margin-right: 10px;
        font-size: $size-5;
      }
    }
  }

}

.panel__body{
  .section-title {
    display: none;
  }
}

.mileage-wrapper {
  .section-body {
    // margin-top: -.85em;
    padding-left: 1em;
    background-color: hsl(0, 2%, 90%) !important;
    display: flex;
    app-question-switcher {
      display: inline-block;
      width: 49%;
      align-items: center;
      .label {
        display:inline;
      }
      .radio {
        display: inline-block;
      }
      .field {
        margin: 0;
      }
      app-question-number {
        input {
          display: inline;
          width: 50%;
        }
      }

      .question-container {
        height: 100%;
        display: flex;
        align-items: center;
      }


    }
  }
}

.form-horizontal {
  .question-container {
    .field {
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }

      label {
        margin: 0 1rem 0 0;
      }

      .radio-group {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .radio label {
          display: flex;
        }
      }

      .label + .radio-group {
        margin-left: 10px;
      }

    }
  }
}

// Display form in 3 columns
.tire-health-report-wrapper {
  margin-top: 0;
  & > .panel > .panel__body{
    display: grid;
    grid-template-columns: 1fr 1.75fr 1fr;
    grid-gap: 0;
  }

  .tire-condition__image {
    &::after {
      content: '';
      position: absolute;
      bottom: 8px;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 25px solid $grey-lighter;
      transform: translate(100%, 100%) rotate(30deg);
      transform-origin: center left;
    }
  }

  .left {
    .tire-condition__image {
      &::after {
        right: 1px;
        transform: translate(100%, 100%) rotate(30deg);
      }
    }
  }

  .right {
    margin-top: 0;
    .tire-condition__image {
      &::after {
        left: -25px;
        transform: translate(100%, 100%) rotate(140deg);
      }
    }
  }

  .center {
    margin-top: 0;
    background-size: 90%;
    background: url("../assets/images/car-view-top.png") no-repeat top 45% center;

    @media screen and (min-width: $size-tablet) {
      background-position: top 20% center;
    }
  }

}

// complete-vehicle-inspection

.complete-vehicle-inspection {
  background-color: white !important; // must be white to print


  .maintenance-services {
    .section-title {
        background-color: var(--theme-primary-color);
    }

    .section-body {

      // Panel rows - Maintenance Service Item
      .panel {

        .section-body {
          // background-color: white;
          // display: flex;
          // flex-flow: row nowrap;
          // justify-content: space-between;
          // align-items: center;

          .radio-group {

          }

        }

      }
    }



  }
  // END Maintenance Service Item

}

.maintenance-service-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
}

.maintenance-service-item__heading {

    width: calc(7 / 12 * 100%);
    display: flex;
    align-items: center;
    position: relative;

    .image-upload__icon {
        width: 20px;
        input[type="file"] {
            position: absolute;
            pointer-events: none;
            opacity: 0;
        }
    }

    .image-upload__label {
        position: relative;
        cursor: pointer;
    }

    .image-upload__count {
        position: absolute;
        bottom: -4px;
        right: -4px;
        padding: 0px 1px;
        background-color: #ffffff;
        color: #3f87a6;
        font-size: 0.55rem;
    }

    .title {
        margin-left: 10px;
        font-size: $size-6;

        @media screen and (min-width: $size-tablet) {
          font-size: $size-4;
        }
    }

}

.maintenance-service-item__rating {
    width: calc(5 / 12 * 100%);
    max-width: 185px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;

  @media screen and (min-width: $size-tablet) {
    width: 200px;
  }

    // Option Item Status - 1 Option Suggestted
    &.option-item-status__1 {
      justify-content: center;
    }

    .rating-item {
        flex: 1;
        width: calc(4 / 12 * 100% - 2px);
        max-width: calc(4 / 12 * 100% - 2px);

        input[type="radio"] + label.rating-item__label, input[type="checkbox"] + label.rating-item__label {
            position: relative;
            transition: all .2s .15s ease-in;

            overflow: hidden;
            .title {
                opacity: 1;
                transform: translate(0, 0);
                transition: transform .2s .15s ease-in, opacity .1s .2s ease-in;
                font-size: $size-6;
            }

            .icon {
                position: absolute;
                top: 0;
                left: 50%;
                right: 50%;
                bottom: 0;
                transform: translate(-50%, -100%);
                width: 25px;
                opacity: 1;
                transition: transform .15s .1s ease-out;
            }
        }

        &.is-required {
            .rating-item__label {
                border-color: hsl(348, 100%, 61%);
                color: hsl(348, 100%, 61%);
            }
            input[type="radio"]:checked + label.rating-item__label,
            input[type="checkbox"]:checked + label.rating-item__label {
                background: hsl(348, 100%, 61%);
            }
        }

        &.is-suggested {
            .rating-item__label {
                border-color: hsl(36, 99%, 67%);
                color: hsl(36, 99%, 67%);
            }
            input[type="radio"]:checked + label.rating-item__label,
            input[type="checkbox"]:checked + label.rating-item__label {
                background: hsl(36, 99%, 67%);
            }
        }

        &.is-ok {
            .rating-item__label {
                border-color: hsl(141, 71%, 48%);
                color: hsl(141, 71%, 48%);
            }
            input[type="radio"]:checked + label.rating-item__label,
            input[type="checkbox"]:checked + label.rating-item__label {
                background: hsl(141, 71%, 48%);
            }
        }

        input[type="radio"], input[type="checkbox"] {
            display: none;
        }

        label {
            border: 2px solid red;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 5px;
            margin: 4px;
            cursor: pointer;
            height: 100%;
        }

        input[type="radio"]:checked + label.rating-item__label,
        input[type="checkbox"]:checked + label.rating-item__label {
            color: #ffffff;
            transition: all .2s ease-out;

            .title {
                opacity: 0;
                transform: translate(0, 100%);
                transition: transform .2s ease-out, opacity .1s .07s ease-out;
            }

            .icon {
                opacity: 1;
                transform: translate(-50%, 0%);
                transition: transform .15s ease-in;
            }
        }
    }
}


// Tire Health Report Wheel status
.vehicle {
    position: relative;

    & > .panel {
      height: 100%;
      & > .section-body {
        height: 100%;
        position: relative;
      }
    }
}

.vehicle__img {
    height: 100%;
    width: 100%;
    object-fit: contain;

    img {
        display: block;
        width: 80%;
        height: auto;
        margin: 0 auto;
    }
}

.vehicle__front, .vehicle__rear {
    position: absolute;
    left: 0;
    right: 0;

    app-section {
      margin-top: 0;
    }
  & > .panel > .section-body {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0%;
    right: 0%;
  }
}

.vehicle__front {
    top: 21%;
}
.vehicle__rear {
    bottom: 42%;
}

.vehicle__wheel, .vehicle__wheel-axle {
    display: flex;
}

.vehicle__wheel {
    position: relative;
    // flex: 0 0 50px;
    justify-content: flex-end;
    img {
        height: 85px;
        width: auto;
        object-fit: contain;
    }
}

.vehicle__wheel-axle + .vehicle__wheel {
    justify-content: flex-start;
}

.vehicle__wheel-axle {

  flex: 1;

  & > .section-header {
    flex: 1;
  }

  & > .panel > .section-body {
    display: flex;
    flex: 1 1 0%;
    position: relative;
    width: 100%;
    background-color: $grey-lighter;
    flex-direction: row;
    padding: .95rem;
    justify-content: space-around;

    .title {
        margin-bottom: .5rem;
    }

    .input {
        padding: .25rem;
    }

    .field {
      margin: 0;
      padding: .25em;


      @media screen and (min-width: $size-tablet) {
        padding: .85em;
      }

    }

    .label {
      display: none;
    }

  }

}

.vehicle__wheel-alignment {

  background: $grey-lighter;
  padding: .35rem;
  display: block;

  position: absolute;
  left: 0;
  right: 0;
  top: 36%;

  .question-container {
    .field {
      text-align: center;

      .radio-group {
        display: flex;
        justify-content: center;
        .radio {
          margin-right: 10px;
        }
      }
    }
  }
}

app-section + app-section {
  margin-top: 1.5rem;
  display: block;
}

.courtesy-section-1
{
  & > .panel > .section-body {
    display: flex;
    padding-top: 1.5rem !important;
  }
}

.courtesy-section__column {
  flex: 1;
  width: 50%;
  margin: 0;

  .panel > .section-body {
    display: flex;
    flex-flow: column nowrap;

    app-question-radio {
      display: block;
      margin: .15rem 0;
    }

    .field {
      display: flex;
      justify-content: space-between;
      .label {
        margin: 0;
      }
    }

    .radio-group {
      display: flex;
      align-items: center;
      margin: 0 30px;
      .radio + .radio {
        margin-left: 10px;
      }
    }

    app-question-select {
      .field {
        .label {
          flex: .5;
        }
      }
    }

    .select {
      flex: 1;
      margin-left: 20px;
      select {
        width: 100%;
      }
    }

  }
}

.oil-change-section {
    .panel > .section-body {
      display: flex;
      justify-content: space-between;
    }

    app-question-switcher {
      flex: 1;
      padding: 0 10px;
    }
}

.rotate-tires-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  app-question-radio {
    .field {
      display: flex;
      align-items: center;
      justify-content: center;
      & > .label {
        margin-right: .85rem;
      }
    }

    .label {
      margin: 0;
    }

    .radio-group {
      display: flex;
      align-items: center;
    }

    .radio {
      margin-right: .85rem;
    }
  }
}

// Tire Health Summary Items
.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.summary-item__image {
  width: 35px;
  margin-right: 1rem;
  text-align: center;

  img {
    width: auto;
    height: 25px;
  }
}

.summary-item__input {
  flex: 1;
}

.summary-item__content {
  flex: 1;

  p {
    margin-top: 0;
    font-size: 1rem;
    line-height: 1.45;
    margin-bottom: 1.5rem;
  }
}
