html {
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html, body {
    padding: 0;
    margin: 0;
}

*, *::before, *::after {
    box-sizing: border-box;
}

a {
    color: var(--theme-primary-color);
    transition: color .2s ease-out;
}

ul.unstyled {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1rem;

    li {
        font-size: .825em;
    }
}

@import "ngx-smart-modal/ngx-smart-modal";

.nsm-dialog.nsm-dialog-open {
    padding: 0;
}

.nsm-content {
  padding: 0;
}

.is-error {
    color: firebrick !important;
}

.is-good {
  color: #4cae4c;
}

.is-note {
  color: $blue;
}

hr.hr {
    border-color: #d2d2d2;
    opacity: 0.4;
}
