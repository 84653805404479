.list-group {

    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;

    margin-bottom: .5rem;
}

.list-group__item {
    display: flex;

    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    padding: .75rem;
    background-color: $white;
    border: 1px solid $grey-lighter;
    
    &:not(:last-child) {
        margin-bottom: .5rem;
    }

    &.is-disabled {
        background-color: #e6e6e6;
        border-color: #e0e0e0;

        .list-group__item-media {
            opacity: .5;
        }

    }
}

.list-group__item-media {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 0 0%;
}

.list-group__item-media__img {
    margin-right: 15px;
    text-align: center;
    img {
        max-height: 25px;
        max-width: 30px;
        width: auto;
    }
}

.list-group__item-heading {
    font-size: $size-5;
    margin: 0;
    padding: 0;
}

.list-group__item-extra {
    font-weight: $weight-bold;
    font-size: $size-5;
}

.list-group__item-accessory {
    max-width: 25px;
}
