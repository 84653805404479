
textarea, .input {
    width: 100%;
    max-width: 100%;
    padding: .5em;
    display: block;
    font-size: 1rem;

    border: 1px solid transparent;
    border-radius: 3px;

    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
    box-shadow: none;
    height: 2.25em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    position: relative;
    vertical-align: top;
    background-color: $white;
    border-color: #dbdbdb;
    color: #363636;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);

}

.field{
    margin-bottom: 0.5rem;
}

.select {
    display: inline-block;
    max-width: 100%;
    position: relative;
    vertical-align: top;

    &::after {
        border: 1px solid #3273dc;
        border-right: 0;
        border-top: 0;
        content: " ";
        display: block;
        height: 0.5em;
        pointer-events: none;
        position: absolute;
        transform: rotate(-45deg);
        transform-origin: center;
        width: 0.5em;
        margin-top: -0.375em;
        right: 1.125em;
        top: 50%;
        z-index: 4;
    }

    select:focus, select.is-focused, select:active, select.is-active {
        border-color: #3273dc;
        box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
    }

    &.is-loading select {
        animation: border-pulse 1.5s ease-in-out alternate infinite;
    }
}

.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    position: relative;
    vertical-align: top;
    background-color: white;
    border-color: #dbdbdb;
    color: #363636;
    cursor: pointer;
    display: block;
    font-size: 1em;
    max-width: 100%;

    padding-right: 2.5em;

    transition: all .3s ease-out;

    &:disabled {
        background-color: #e2e2e2;
        color: #949494;
    }
}

.select.is-sm {
    select, &:not(.is-multiple) {
        height: 1.975em;
    }

    select {
        padding-bottom: calc(0.275em - 1px);
        padding-top: calc(0.275em - 1px);
        font-size: .875em;
    }

    &::after {
        margin-top: -.275em;
    }
}

.select:not(.is-multiple) {
    height: 2.25em;
}

.field:not(:last-child), .select:not(:last-child) {
    margin-bottom: 0.75rem;
}

.columns.form-row {
    .column {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
}

@keyframes border-pulse {
    from {
        border-color: var(--theme-primary-color);
    }

    to {
        border-color: #cecece;
    }
}

.label:not(:last-child) {
    margin-bottom: 0.5em;
}

.label {
    color: #363636;
    display: block;
    font-size: 1rem;
    font-weight: 700;
}

.checkbox + .label {
    display: inline-block;
}

.textarea:not([rows]) {
    max-height: 600px;
    min-height: 120px;
}

.textarea {
    display: block;
    max-width: 100%;
    min-width: 100%;
    padding: 0.625em;
    resize: vertical;
}

.horizontal-form {
    display: flex;
    align-items: center;
    margin-bottom: .75rem;

    & > div[class^="horizontal-form"] {
        flex: 1 1 0%;
        display: block;
    }

    .horizontal-form__left {
        text-align: right;
        margin-right: 10px;
    }

    .select, .select select {
        width: 100%;
    }
}

fieldset {
    border: none;
    padding: 0;
}

.has-floated-label-margin {
  margin-top: .5rem;
}

.has-floated-label {
  --input-padding: .5em;

  position: relative;
  padding: 0;
  margin-top: .5rem;

  input {
    height: 100%;
    width: 100%;
    display: block;
    padding: var(--input-padding);
    margin: 0;
    border: 0;
    font-size: 1rem;
    line-height: 1.5;

    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none;

    &::-webkit-input-placeholder {
        opacity: 0;
    }
    &::-moz-placeholder {
        opacity: 0;
    }
    &:-ms-input-placeholder {
        opacity: 0;
    }
    &:-moz-placeholder
    {
        opacity: 0;
    }
  }

  .label {
    height: 100%;
    line-height: 1;

    color: rgba(0, 0, 0, .65);
    font-weight: normal;

    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;

    pointer-events: none;

    transform: translate(var(--input-padding), 0);
    transform-origin: left center;
    transition: transform 150ms ease-out, color 100ms ease-out;
  }

    input:focus + label {
        color: var(--theme-primary-color);
    }
    input:valid + label {
        transform: translate(0, -90%) scale(.75);
    }
}

.field + .field > .has-floated-label {
    margin-top: .9rem;
}
