.nav-list {

    display: flex;
    margin: 0 0 1rem;

    a {
        margin: .35rem 0;
        text-decoration: none;
        color: $black-ter;
        font-weight: $weight-bold;
    }

    &.is-vertical {
        flex-flow: column nowrap;
    }
}