.btn {
    font-size: 1rem;
    display: block;
    width: auto;
    padding: 10px 30px;
    background-color: $white-ter;
    border: 1px solid $grey-lighter;
    cursor: pointer;

    transition: background-color .2s ease-out;

    &:hover {
        background-color: $white-bis;
    }

    &.is-rl-sm {
      padding: 3px;
      font-size: .4rem;
    }

    &.is-sm {
        padding: 10px;
        font-size: .725rem;
    }

    &.is-short {
      padding: 6px 20px;
    }

    &.is-default {
        background-color: $white;
    }

    &.is-primary {
        color: $primary-invert !important;
        background-color: var(--theme-primary-color);
        border: 1px solid var(--theme-primary-darker-color);

        &:hover {
            background-color: var(--theme-primary-darker-color);
            border: 1px solid var(--theme-primary-darker-color);
        }
        &:disabled {
            background-color: $grey-light;
            border: 1px solid $grey-light;
        }

    }

  &.is-unselected {
    background-color: #4A4A4A;
    border: 1px solid #4A4A4A;
    color: $primary-invert !important;
  }

    &.is-centered {
        margin-left: auto;
        margin-right: auto;
    }

    &.is-block {
        display: block;
        width: 100%;
    }

    &.is-inline {
        display: inline-block;
        width: auto;
        padding: 2px 5px;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &.has-icon-left {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        &.is-inline {
            display: inline-flex;
        }

        .icon {
            line-height: 1;
            display: block;
            max-width: 17px;
            margin-right: .65rem;
            position: relative;
            top: 1px;
        }
    }

    &.has-loading-icon {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;

        .loading {
            margin-left: 8px;
            width: 20px;
        }
    }

    &.btn-link {
        padding: 0;
        border: none;
        background-color: transparent;
        font-size: .875rem;

        &:not(:last-child) {
            margin-bottom: .5rem;
        }

        &.is-primary {
            background: transparent;
            color: var(--theme-primary-color) !important;

            &:hover {
                background-color: transparent;
                border: none;
            }
            &:disabled {
                background-color: transparent;
                border: none;
            }
        }
    }

}

.clickable {
  cursor: pointer;
}

a.btn {
    text-decoration: none;
    text-align: center;
    display: inline-block;
}
