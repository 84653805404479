.nsm-dialog.nsm-dialog-open {
  overflow: auto;
  max-height: 90vh;
}

.modal-wide.nsm-dialog.nsm-dialog-open {
  max-width: 80% !important;
}

.modal-extra-wide.nsm-dialog.nsm-dialog-open {
  max-width: 100% !important;
}
.modal-extra-wide.nsm-dialog.nsm-dialog-open>.nsm-content {
  margin: 0 !important;
}
.no-top-padding > .has-padding-y-1 {
  padding-top: 0 !important;
}

.nsm-dialog-btn-close {
  background-color: white;
  border-radius: .25rem;
}
