.has-text-left {
    text-align: left;
}
.has-text-centered {
    text-align: center;
}
.has-text-right {
    text-align: right;
}

.has-padding {
    padding: .85rem !important;
}

.has-padding-1 {
    padding: $spacing-1 !important;
}

.has-padding-2 {
    padding: $spacing-2 !important;
}

.has-padding-x {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.has-padding-x-2 {
    padding-left: $spacing-2 !important;
    padding-right: $spacing-2 !important;
}

.has-padding-y {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}
.has-padding-y-1 {
    padding-top: $spacing-1 !important;
    padding-bottom: $spacing-1 !important;
}
.has-padding-y-2 {
    padding-top: $spacing-2 !important;
    padding-bottom: $spacing-2 !important;
}
.has-padding-y-3 {
    padding-top: $spacing-3 !important;
    padding-bottom: $spacing-3 !important;
}

// No Padding
.has-no-padding {
    padding: 0 !important;
}

.has-no-padding-y {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.has-no-margin {
    margin: 0 !important;
}

.has-no-margin-y {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.has-margin-y-1 {
    margin-top: $spacing-1 !important;
    margin-bottom: $spacing-1 !important;
}
.has-margin-y-2 {
    margin-top: $spacing-2 !important;
    margin-bottom: $spacing-2 !important;
}
.has-margin-y-bottom-2 {
    margin-top: 0 !important;
    margin-bottom: $spacing-2 !important;
}
.has-margin-y-3 {
    margin-top: $spacing-3 !important;
    margin-bottom: $spacing-3 !important;
}
.has-margin-y-bottom-3 {
    margin-top: 0 !important;
    margin-bottom: $spacing-3 !important;
}
.has-margin-y-4 {
    margin-top: $spacing-4 !important;
    margin-bottom: $spacing-4 !important;
}

.has-arrow {
    position: relative;
    transition: color .15s ease-out;

    &::after {
        content: '';
        height: 0;
        width: 0;
        display: block;

        position: absolute;
        top: 5px;
    }

    &.arrow-right::after {
        right: -10px;
        border-left: 4px solid $white;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        transition: all .2s ease-out;
    }
}

.has-divider-left {
    position: relative;
    &::before {
        content: '';
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: $grey-light;
    }
}

.has-divider-top {
    position: relative;
    &::before {
        content: '';
        height: 1px;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background-color: $grey-light;
    }

    &:first-child {
        &::before {
            display: none;
        }
    }
}

.is-danger {
    color: $red !important;
}
.is-success {
    color: $green !important;
}
.is-warning {
    color: $yellow !important;
}

// BG Colors

.bg-transparent {
    background-color: transparent !important;
    border: none !important;
}

.bg-light {
    background-color: $white-ter !important;
}

.bg-lighter {
    background-color: $white !important;
}

.bg-grey-light {
    background-color: $grey-light !important;
}

.bg-grey-lighter {
    background-color: $grey-lighter !important;
}

.bg-grey-lightest {
    background-color: $grey-lightest !important;
}



.is-light {
    color: $white-ter !important;
}

.is-lighter {
    color: $white !important;
}

.is-grey-lighter {
    color: $grey-lighter !important;
}

.is-grey-light {
    color: $grey-light !important;
}
.is-grey {
    color: $grey !important;
}
.is-grey-dark {
    color: $grey-dark !important;
}
.is-grey-darker {
    color: $grey-darker !important;
}

.is-selectable {
    position: relative;
    &:focus {
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            top: 0; bottom: 0;
            left: 0; right: 0;
            background: rgba(0, 0, 0, .15);
        }
    }
}

.is-hidden {
    display: none;
}

.is-inline-block {
  display: inline-block;
}

.display-flex {
  display: flex;
}
