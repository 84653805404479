.table {
    background-color: white;
    color: #363636;
    margin-bottom: 1.5rem;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.table thead td, .table thead th {
    border-width: 0 0 2px;
    color: #363636;
}

.table th {
    color: #363636;
    text-align: left;
}
.table td, .table th {
    border: 1px solid #dbdbdb;
    border-width: 0 0 1px;
    padding: 0.5em 0.75em;
    vertical-align: top;
}
table th {
    color: #363636;
}
table td, table th {
    text-align: left;
    vertical-align: top;
}
td, th {
    padding: 0;
    text-align: left;
}


.reason-for-visit-table {
    table-layout: fixed;
    width: 100%;

    &.table tbody td, &.table thead th {
        font-size: $size-6;
        border: none;
        padding: 0.5em 0.35em;
        word-wrap: break-word;
    }

    &.table thead th{
        background-color: #B1D7FD;
        color: #145EA6;
        text-transform: uppercase;
        font-weight: bold;
    }

    &.table thead td {
        padding-top: .925rem;
        padding-bottom: .925rem;
    }

    &.table tbody tr:nth-child(even) {
        background-color: #F2F9FF;
    }
}